export const environment = {
  Wp: false,
  IDTecnico: "RT",
  IDCliente: "C",
  IDAdmin: "AZ",
  IDRoot: "SA",
  ClientiType: ['CA', 'C', 'AC'],
  filtrife: [],
  legenda:[
    {area: "RMA.rma", legenda: [
        {label: "RMA.legenda.stato1", color: "#E95A3B"},
        {label: "RMA.legenda.stato2", color: "#50270A"},
        {label: "RMA.legenda.stato3", color: "#758fef"},
        {label: "RMA.legenda.stato4", color: "#F6BE30"},
        {label: "RMA.legenda.stato5", color: "#6DA447"}
      ]
    }],
  production: true,
  apiUrl: 'https://service.klimaitalia.it/api',
  bbbUrl: 'https://newsbs.ddns.net/bigbluebutton',
  langPrefix: './assets/i18n/',
  langSuffix: '.json',
  ClientebaseUrl: '/ecommerce/profilo',
  baseUrl: '/task/calendario',
  unloggedHref: '/ecommerce/cat',
  href: '/Login',
  homepages: [
    {Ruoli: ["AC","C"], link: "/ecommerce/profilo"}
  ],
  //#region Layout Config
  logoPath: 'assets/images/KlimaNuovo.png',
  loginMsg: 'Se sei un CAT richiedi le tue credenziali ad assistenza@klimaitalia.it',
  loginbgPath: 'assets/images/klimasede.jpeg',
  icon:'assets/images/KlimaIMGxicon.png',
  logoBg: 'skin1', // six possible values: skin(1/2/3/4/5/6),
  navbarbg: 'skin2', // six possible values: skin(1/2/3/4/5/6)
  sidebarbg: 'skin1', // six possible values: skin(1/2/3/4/5/6)
  userCircleColor: "",
  theme: 'light',
  dir: 'ltr', // two possible values: ltr, rtl
  layout: 'vertical', // fixed value. shouldn't be changed.
  sidebartype: 'full', // four possible values: full, iconbar, overlay, mini-sidebar
  sidebarpos: 'fixed', // two possible values: fixed, absolute
  headerpos: 'fixed', // two possible values: fixed, absolute
  boxed: 'full', // two possible values: full, boxed
  //#endregion
  //#region Moduli
  Modules:{
    "twoFactorAuth": false,
    "Admin": true,
    "Personale": true,
    "Clienti": true,
    "Interventi": false,
    "Prima_Nota": false,
    "Listini": false,
    "Progetti": true,
    "Prodotti": true,
    "Documenti": false,
    "Scadenzario": false,
    "Spedizioni": false,
    "Stampe": true,
    "Task": true,
    "Shared": false,
    "Dashboard": false,
    "Settings": true,
    "Formazione": false,
    "VersioneCarbotti": false,
    "PartiProdotto": false,
    "Parti": false,
    "Rma": true,
    "Ecommerce": true,
    "ScadenzeFiglie": false,
    "Registrazione": true,
    "Ticket": true,
    "Acquisti": false,
    "taskboard": false,
    "Camera":false,
    "Valutazioni":false
  },
//#endregion
  //#region Addon
  Personale: {
    Corsi: false,
  },
  PartiAddOn: [false],//  categorie associate
  TaskAddon: {
    checkList : false,
    sidebarFilter: [
      {
        modificabile: true,
        nome: 'Cerca',
        oggetti: ['pdv'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-12',
        visibleifProhibited: true
      },
    ],
    ListTableHeaderField:  [
      {nome: "Titolo", oggetti: ['Ticket #','id', 'nome']},
      {nome: "Modello", oggetti: ['prodotto']},
      {nome: "Luogo di Intervento", oggettisec: ['acquirente','acquirente','acquirente','acquirente'], oggetti: ['ritiro','ritiro','ritiro','ritiro'], type: "object", field:['nominativo','indirizzo','citta', 'provincia']},
      {nome: "Cat Assegnato", oggetti: ['assistenza'], type: "object", field:['nominativo'], prohibitedRoles:['C', 'AC']},
      {nome: "Data Segnalazione", oggetti: ['data_inizio_prevista'], inputType: "date"},
      {nome: "Data Presa in Carico", oggetti: ['data_apertura_centro'], inputType: "date", prohibitedRoles:['C', 'AC']},
      {nome: "Data Fine Intervento", oggetti: ['data_fine_effettiva'], inputType: "date", prohibitedRoles:['C', 'AC']},
    ]
  },
  ClientiAddOn: {
    Register:{cf:true, cu:true},
    codAdhoc:true,
    enable:true,
    info:false,
    emailAccount: true,
    commerciale: true,
    codice_univoco:true,
    cf:true,
    segnalatore: true,
    segnalatoreInterno: true,
    sedi: false,
    TipoSedePrimaria: false,
    attivita: false,
    geolocalizzazione: true,
    ruolo: true,
    Modifica: false,
    prodotti: true,
    rma: true,
    accesso: true,
    referenti: false,
    interventi: false,
    note_private: false,// rende invisibile il campo note al cliente
    rating: true,
    ruoliAccesso:true,
    compilazione:false,
    luogoNascita: false,
    dataNascita: false,
    statoCivile:false,
    titoloStudio: false,
    occupazione: false,
    invalidita: false,
    legge104: false,
    diagnosi: false,
    accompagnamento:false,
    pensione: false,
    tutelare: false,
    medico: false,
    telMedico: false,
    terapia:false
  },
  ProgettiAddon: {
    commerciale: false,
    segnalatore: false,
    categoriaVoceDiCosto: false,
    lavorato: false,
    OggettoNascosto: true,
    HideColore: true,
    HidePrezzo: false,
    gestore: true,
    costoSpedizione: true,
    personalizzazioni: false,
    destinazioni:true,
    inviaEmail:true,
    scontoAdhoc: true,
    HideIvato: true,
    pagato: true,
    idProdotto: 69,
    CampiAggiuntivi: [ // campi aggiuntiv voci di costo
      {nome:'Sconto %', oggetti:['sconto'], modificabile: true, inputType: 'number',  MathOperation: {MathType: '-', NumType: 'percentage'}},
      {nome:'Sconto %', oggetti:['sconto2'], modificabile: true, inputType: 'number', MathOperation: {MathType: '-', NumType: 'percentage'}},
      {nome:'Sconto %', oggetti:['sconto3'], modificabile: true, inputType: 'number', MathOperation: {MathType: '-', NumType: 'percentage'}},
      /*  {nome:'Trasporto €', oggetti:['spedizione'], modificabile: true, inputType: 'number', MathOperation: {MathType: '+', NumType: 'number'}}, */
      /* {nome:'Disponibilità', oggetti:['disponibilitaProdotto'], modificabile: false, inputType: 'number'},
      {nome:'Merce in Arrivo', oggetti:['materasso'], modificabile: false, inputType: 'number'},
      // {nome:'Data Merce in Arrivo', oggetti:['dataMaterasso'], modificabile: false, inputType: 'date'},
      {nome:'Data Evas. Prev.', oggetti:['dataConsegna'], modificabile: true, inputType: 'date'}, */
    ],
    CampiAggiuntiviLista: [
      {nome:'Offerta.Conferma', oggetti:['data_conferma'], type: 'date'},
      {nome:'CO Adhoc', oggetti:['codiceOrdineAdhoc'], type: 'text'},
    ],
    filters: [
      {nome:'Offerta.Codice', oggetti:['codice'], modificabile:true, inputType: 'text', class: 'col-lg-9'},
    ],
    Revisioni: true,
    tipoPagamento: true,
    banche: true,
    francoDeposito: true,
    ColoriLista: [
      {colore: "#ff0000", campo: "data_creazione", label: "Ordine da Lavorare" },
      {colore: "#9d9800", campo: "data_emissione", label: "Ordine in attesa di accettazione"},
      {colore: "#0000ff", campo: "data_accettazione", label: "Ordine confermato dal cliente"},
      {colore: "#7979ff", campo: "data_conferma", label: "Ordine inviato in adhoc"}
    ],
    CampiOfferta: [
      {
        prevDivClass: 'col-12',
        modificabile: false,
        nome: 'Ragione Sociale:',
        oggetti: ['clienteObject'],
        field: ['nominativo'],
        inputType: 'text',
        type:'object',
        hideif: {fieldnotexist: 'clienteObject'},
        titleStyle: {'font-size': 'medium', 'margin-right': '5px'},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        inputStyle: {font: 'message-box',     'font-size': '20px'},
        visibleifProhibited: true,
      },
      {
        modificabile: false,
        nome: 'Indirizzo:',
        oggetti: ['clienteObject'],
        field: ['indirizzo'],
        hideif: {fieldnotexist: 'clienteObject'},
        inputType: 'text',
        type:'object',
        titleStyle: {'font-size': 'medium', 'margin-right': '5px'},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        inputStyle: {font: 'message-box',     'font-size': '20px'},
        visibleifProhibited: true,
      },
      {
        modificabile: false,
        nome: 'Cap:',
        oggetti: ['clienteObject'],
        hideif: {fieldnotexist: 'clienteObject'},
        field: ['cap'],
        inputType: 'text',
        type:'object',
        titleStyle: {'font-size': 'medium', 'margin-right': '5px'},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        inputStyle: {font: 'message-box',     'font-size': '20px'},
        visibleifProhibited: true,
      },
      {
        modificabile: false,
        nome: 'Città:',
        oggetti: ['clienteObject'],
        hideif: {fieldnotexist: 'clienteObject'},
        field: ['citta'],
        inputType: 'text',
        type:'object',
        titleStyle: {'font-size': 'medium', 'margin-right': '5px'},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        inputStyle: {font: 'message-box',     'font-size': '20px'},
        visibleifProhibited: true,
      },
      {
        modificabile: false,
        nome: 'Provincia:',
        oggetti: ['clienteObject'],
        hideif: {fieldnotexist: 'clienteObject'},
        field: ['provincia'],
        inputType: 'text',
        type:'object',
        titleStyle: {'font-size': 'medium', 'margin-right': '5px'},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        inputStyle: {font: 'message-box',     'font-size': '20px'},
        visibleifProhibited: true,
      },
      {
        modificabile: false,
        nome: 'Partita IVA:',
        oggetti: ['clienteObject'],
        hideif: {fieldnotexist: 'clienteObject'},
        field: ['piva'],
        inputType: 'text',
        type:'object',
        titleStyle: {'font-size': 'medium', 'margin-right': '5px'},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        inputStyle: {font: 'message-box',     'font-size': '20px'},
        visibleifProhibited: true,
      },
      {
        modificabile: false,
        nome: 'Codice AdHoc:',
        oggetti: ['clienteObject'],
        hideif: {fieldnotexist: 'clienteObject'},
        field: ['codAdhoc'],
        inputType: 'text',
        type:'object',
        titleStyle: {'font-size': 'medium', 'margin-right': '5px'},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        inputStyle: {font: 'message-box',     'font-size': '20px'},
        visibleifProhibited: true,
      },
      {
        modificabile: false,
        nome: 'Telefono:',
        oggetti: ['clienteObject'],
        hideif: {fieldnotexist: 'clienteObject'},
        field: ['telefono'],
        inputType: 'text',
        type:'object',
        titleStyle: {'font-size': 'medium', 'margin-right': '5px'},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        inputStyle: {font: 'message-box',     'font-size': '20px'},
        visibleifProhibited: true,
      },
      {
        modificabile: false,
        nome: 'E-mail:',
        oggetti: ['clienteObject'],
        hideif: {fieldnotexist: 'clienteObject'},
        field: ['email'],
        inputType: 'text',
        type:'object',
        titleStyle: {'font-size': 'medium', 'margin-right': '5px'},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        inputStyle: {font: 'message-box',     'font-size': '20px'},
        visibleifProhibited: true,
      },
      
      // {
      //   prevDivClass: 'col-12',
      //   modificabile:true,
      //   nome: 'Agente',
      //   oggetti: ['agente'],
      //   inputType: 'singleDropdown',
      //   source: 'Agenti',
      //   internalOutput: 'agenteObject',
      //   placeholder: 'Seleziona',
      //   OnFilter: 'filtroAgente',
      //   OnChange: 'OnAgenteChange',
      //   settings: {
      //     singleSelection: true,
      //     closeDropDownOnSelection: true,
      //     singleOutput:true,
      //     idField: 'id',
      //     textField: 'fullName',
      //     allowSearchFilter: true,

      //   },
      //   titleClass: 'control-label col-form-label'  ,
      //   class: 'col-lg-3',
      //   visibleifProhibited: true
      // },
      // {
      //   modificabile:true,
      //   nome: 'Offerta.Responsabile',
      //   oggetti: ['manager'],
      //   inputType: 'singleDropdown',
      //   source: 'managers',
      //   internalOutput: 'managerObject',
      //   OnFilter: 'filtroManager',
      //   OnChange: 'OnManagerChange',
      //   placeholder: 'Seleziona',
      //   settings: {
      //     singleSelection: true,
      //     closeDropDownOnSelection: true,
      //     singleOutput:true,
      //     idField: 'id',
      //     textField: 'fullName',
      //     searchPlaceholderText: "inserire almeno 3 caratteri",
      //     allowSearchFilter: true,
      //     allowRemoteDataSearch: true,
      //   },
      //   titleClass: 'control-label col-form-label'  ,
      //   class: 'col-lg-3',
      //   visibleifProhibited: true
      // },
      // {
      //   modificabile:true,
      //   nome: 'cliente.commerciale',
      //   oggetti: ['commerciale'],
      //   inputType: 'singleDropdown',
      //   source: 'managers',
      //   internalOutput: 'commerciale',
      //   OnFilter: 'filtroCommerciale',
      //   placeholder: 'Seleziona',
      //   settings: {
      //     singleSelection: true,
      //     closeDropDownOnSelection: true,
      //     singleOutput:true,
      //     idField: 'id',
      //     textField: 'fullName',
      //     searchPlaceholderText: "inserire almeno 3 caratteri",
      //     allowSearchFilter: true,
      //     allowRemoteDataSearch: true,
      //   },
      //   titleClass: 'control-label col-form-label'  ,
      //   class: 'col-lg-3',
      //   visibleifProhibited: true
      // },{
      //   modificabile:true,
      //   nome: 'cliente.segnalatore',
      //   oggetti: ['clienti'],
      //   inputType: 'singleDropdown',
      //   source: 'clienti',
      //   internalOutput: 'segnalatore',
      //   OnFilter: 'filtrosegnalatore',
      //   placeholder: 'Seleziona',
      //   settings: {
      //     singleSelection: true,
      //     closeDropDownOnSelection: true,
      //     singleOutput: true,
      //     idField: 'id',
      //     textField: 'nominativo',
      //     searchPlaceholderText: "inserire almeno 3 caratteri",
      //     allowSearchFilter: true,
      //     allowRemoteDataSearch: true,
      //     noDataAvailablePlaceholderText:"Nessun Cliente trovato ",
      //     moreButton: true,
      //     moreButtonText: "Cerca in AdHoc",
      //   },
      //   titleClass: 'control-label col-form-label'  ,
      //   class: 'col-lg-3',
      //   visibleifProhibited: true
      // },
      {
        modificabile:true,
        nome: 'Gestore',
        oggetti: ['manager'],
        inputType: 'singleDropdown',
        source: 'managers',
        internalOutput: 'gestoreObject',
        OnFilter: 'filtroCommerciale',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput:true,
          idField: 'id',
          textField: 'fullName',
          searchPlaceholderText: "inserire almeno 3 caratteri",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
          excludeIds: [1032, 983]
        },
        titleClass: 'control-label col-form-label'  ,
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Numero Ordine:',
        oggetti: ['codice'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-2',
        placeholder: 'Numero Ordine:',
        visibleifProhibited: true
      },
      {
        prevDivClass: "col-md-12",
        modificabile: true,
        nome: 'Offerta.Creazione',
        oggetti: ['data_creazione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Offerta',
        oggetti: ['data_emissione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Accettazione',
        oggetti: ['data_accettazione'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Offerta.Conferma',
        oggetti: ['data_conferma'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'CO',
        oggetti: ['codiceOrdine'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true,
        disabledif: {fieldexist: 'codiceOrdine'},
        hideif:{fieldnotexist: 'codiceOrdine'}
      },
      {
        modificabile: true,
        nome: 'CO AdHoc',
        oggetti: ['codiceOrdineAdhoc'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true,
        disabledif: {fieldexist: 'codiceOrdineAdhoc'},
        hideif:{fieldnotexist: 'codiceOrdineAdhoc'}
      },
      {
        modificabile: true,
        nome: 'Note',
        oggetti: ['note'],
        inputType: 'textarea',
        titleClass: 'control-label col-form-label',
        class: 'col-md-6',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Note Private',
        oggetti: ['note_private'],
        inputType: 'textarea',
        titleClass: 'control-label col-form-label',
        class: 'col-md-6',
        visibleifProhibited: false
      },
      {
        modificabile: true,
        nome: 'Tracking:',
        oggetti: ['tracking'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-12',
        placeholder: 'Tracking:',
        visibleifProhibited: true
      },
      {
        modificabile: false,
        nome: 'Preventivo Inviato:',
        oggetti: ['ordineCliente'],
        inputType: 'html',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-12',
        hideif: {fieldnotexist: 'ordineCliente'},
        placeholder: 'Preventivo Inviato',
        visibleifProhibited: true
      },
    ],
    CampiSottoOfferta: [
      {
        modificabile:true,
        nome: 'Sede',
        oggetti: ['codAdhoc'],
        inputType: 'singleDropdown',
        source: 'sediAdhoc',
        internalOutput: 'sedeObjectAdhoc',
        OnFilter: 'filtroSedeAdhoc',
        OnChange: 'SedeAdhocChange',
        placeholder: 'Seleziona',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput:true,
          idField: 'codAdhoc',
          textField: 'fullAddress',
          searchPlaceholderText: "Cerca",
          allowSearchFilter: true,
          allowRemoteDataSearch: false,
        },
        titleClass: 'control-label col-form-label'  ,
        class: 'col-lg-3',
        prevDivClass: 'col-12',
        visibleifProhibited: true
      },

      {
        prevDivClass: 'col-12',
        modificabile: true,
        nome: 'Nominativo',
        oggetti: ['consegna'],
        field: ['nominativo'],
        inputType: 'text',
        type:'object',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true,
        hideif: {fieldexist: 'sedeObjectAdhoc'}
      },
      {
        modificabile: true,
        nome: 'Indirizzo',
        oggetti: ['consegna'],
        field: ['indirizzo'],
        inputType: 'text',
        type:'object',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true,
        hideif: {fieldexist: 'sedeObjectAdhoc'}
      },
      {
        modificabile: true,
        nome: 'CAP',
        oggetti: ['consegna'],
        field: ['cap'],
        inputType: 'text',
        type:'object',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true,
        hideif: {fieldexist: 'sedeObjectAdhoc'}
      },
      {
        modificabile: true,
        nome: 'Città',
        oggetti: ['consegna'],
        field: ['citta'],
        inputType: 'text',
        type:'object',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true,
        hideif: {fieldexist: 'sedeObjectAdhoc'}
      },
      {
        modificabile: true,
        nome: 'Provincia',
        oggetti: ['consegna'],
        field: ['provincia'],
        inputType: 'text',
        type:'object',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true,
        hideif: {fieldexist: 'sedeObjectAdhoc'}
      },
      {
        modificabile: true,
        nome: 'Telefono',
        oggetti: ['consegna'],
        field: ['telefono'],
        inputType: 'text',
        type:'object',
        titleClass: 'control-label col-form-label',
        class: 'col-md-3',
        visibleifProhibited: true,
      },
      {
        prevDivClass: "col-12",
        modificabile: true,
        nome: 'Check Amministrativo',
        oggetti: ['checkAmm'],
        inputType: 'checkbox',
        titleClass: 'control-label col-form-label',
        class: 'col-md-1',
        visibleifProhibited: true,
      },
      {
        modificabile: true,
        nome: 'Contrassegno',
        oggetti: ['contrassegno'],
        inputType: 'checkbox',
        titleClass: 'control-label col-form-label',
        class: 'col-md-1',
        visibleifProhibited: true,
      },
      {
        modificabile: true,
        nome: 'Porto Franco',
        oggetti: ['portoFranco'],
        inputType: 'checkbox',
        titleClass: 'control-label col-form-label',
        class: 'col-md-1',
        visibleifProhibited: true,
      },
      {
        modificabile: true,
        nome: 'Porto Assegnato',
        oggetti: ['portoAssegnato'],
        inputType: 'checkbox',
        titleClass: 'control-label col-form-label',
        class: 'col-md-1',
        visibleifProhibited: true,
      },
      {
        modificabile: true,
        nome: 'Annullato:',
        oggetti: ['annullato'],
        inputType: 'checkbox',
        titleClass: 'control-label col-form-label',
        class: 'col-md-1',
        visibleifProhibited: true,
      },
      {
        modificabile: true,
        nome: 'Data Annullamento:',
        oggetti: ['dataAnnullato'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-2',
        visibleifProhibited: true,
      },
      {
        modificabile: true,
        nome: 'VS Riferimento ordine N.:',
        oggetti: ['rifOrdine'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-md-2',
        visibleifProhibited: true,
      },
      {
        modificabile: true,
        nome: 'del:',
        oggetti: ['rifData'],
        inputType: 'date',
        titleClass: 'control-label col-form-label',
        class: 'col-md-2',
        visibleifProhibited: true,
      },
    ]
  },
  ProdottiAddOn: {
    sottoTitolo: false,
    shortDescription: false,
    classeEnergetica: false,
    tags: false,
    video: false,
    dettaglio:true,
    files: true,
    prezzoSingolo: true,
    cart: true,
    codice: false,
    codice_produttore: false,
    tipo: true,
    standby: true,
    tipi:false, // tipi di prodotto (1 - 2ante klima)
    cat_image: true,
    lista_prezzo:true,
    TranslatedDesc: false,
    codAdhoc:true,
    DragSort: false,
    dynamicImport: [
      {
        label: 'Importa',
        campi: [
          {
            nome: 'Fornitore',
            modificabile:true,
            oggetti: ['id_fornitore'],
            inputType: 'singleDropdown',
            source: '/anagrafica/clienti/list',
            parameter: [{nome:"page", value: 1}, {nome:"size", value: 200}, {nome: "ruolo", value: "F"}],
            responseHasData: true,
            internalOutput: 'fornitoreObject',
            placeholder: 'Seleziona',
            OutputField: 'id_fornitore',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'id',
              textField: 'nominativo',
              allowSearchFilter: true,
            },
            multipart:true,
            titleClass: 'control-label col-form-label'  ,
            class: 'col-lg-3',
            visibleifProhibited: true

          },
          {
            modificabile: true,
            nome: 'Data',
            oggetti: ['data'],
            multipart:true,
            prevDivClass: 'col-md-12',
            inputType: 'date',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'File Excel',
            oggetti: ['allegato'],
            multipart:true,
            prevDivClass: 'col-md-12',
            inputType: 'file',
            placeholder: '(xls,xlsx) Size Limit: 5.00 MB',
            visibleifProhibited: true
          }
        ],
        url: '/prodotti/listino/import/ricambi'
      }
    ],
    iva:true,
    cat_Sort: false,
    Ricambi: {
      id: 69,
      Elements:[
        {
          nome: 'Ricambi Associati',
          oggetti: ['ricambiObject'],
          type:"table",
          azioni:[
            {ExternalOnClick: 'OnDelete', icon: 'fa-trash', tooltip:'Elimina', color: 'red'}
          ],
          header:[
            {
              oggetti: ['prodotto'],
              modificabile: false,
              nome: 'Ricambio',
            },
            {
              oggetti: ['codiceAdhoc'],
              modificabile: false,
              nome: 'Cod. AdHoc',
            },
            {
              oggetti: ['posizione'],
              modificabile: true,
              nome: 'Posizione',
              inputType: 'text',
            },
          ],
          modificabile: true,
          class: 'col-lg-12'
        },
      ]
    },
    prodottiFilter: [
      {nome: "Cerca", oggetti: ['pdv'], type: "text", filtertype: '69',  class: 'col-lg-9' },
      {nome: "Descrizione", oggetti: ['prodotto'], type: "text", filtertype: '69',  class: 'col-lg-9' },
      {nome: "Codice", oggetti: ['orderByCodice'], type: "hidden", filtertype: '69',  class: 'col-lg-9', value:"ASC" },
    ],
    ProdottiListHeader:[
      {nome: "Nome", oggetti: ["codice"],type: 'text', filtertype: '69' },
      {nome: "Descrizione", oggetti: ["prodotto"],type: 'text', filtertype: '69' },
      {nome: "Cod. Fornitore", oggetti: ["codice_produttore"],type: 'text', filtertype: '69' },
      {nome: "Stock", oggetti: ["magazzino"],type: 'text', filtertype: '69' },
      {nome: "Prodotto", oggetti: ["prodotto"],type: 'text', filtertype: '68' },
      {nome: "Prodotti Associati", oggetti: ['prodottiObject'], field:['prodotto'],type: 'objArray' , filtertype: '69'},
      /*{nome: "Tipologia", oggetti: ['type'], type: 'replace', replacement: [{original: 'C', replace:'CRM'}, {original: 'S', replace:'Scadenza'}, {original: 'R', replace:'Attività'},{original: 'W', replace:'Visita'}]},*/
    ],
    SezioniCodiciSconto: [
      {
        sezione:'',
        campi:[
          {
            oggetti: ['prodotto'],
            modificabile: true,
            nome: 'Ricambio',
            inputType: 'text',
            titleClass: 'control-label col-form-label',
            class: 'col-md-3',
            visibleifProhibited: true
          },
          {
            oggetti: ['codice'],
            modificabile: true,
            nome: 'Codice Articolo',
            inputType: 'text',
            titleClass: 'control-label col-form-label',
            class: 'col-md-3',
            visibleifProhibited: true
          },

          {
            oggetti: ['codiceAdhoc'],
            modificabile: true,
            nome: 'Codice AdHoc',
            inputType: 'text',
            titleClass: 'control-label col-form-label',
            class: 'col-md-3',
            visibleifProhibited: true
          },
          {
            oggetti: ['codice_produttore'],
            modificabile: true,
            nome: 'Codice Fornitore',
            inputType: 'text',
            titleClass: 'control-label col-form-label',
            class: 'col-md-3',
            visibleifProhibited: true
          },
          {
            type:'image',
            oggetti:['id_immagine'],
            imageToServer: 'immagine',
            filename: 'file',
            titleClass: 'control-label col-form-label',
            class: 'col-md-3',
            visibleifProhibited: true,
            modificabile: true
          },
          {
            modificabile:true,
            nome: 'Categoria',
            oggetti: ['categorie'],
            inputType: 'singleDropdown',
            source: '/prodotti/accessori/cat/list/list',
            parameter: [{nome:"page", value: 1}, {nome:"size", value: 200}],
            internalOutput: 'categorieObject',
            placeholder: 'Seleziona',
            OutputField: 'categorie',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: false,
              idField: 'id',
              textField: 'categoria',
              allowSearchFilter: true,
            },
            titleClass: 'control-label col-form-label'  ,
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            nome: 'Prodotti Associati',
            oggetti: ['prodottiObject'],
            class: 'col-lg-12',
            type:"table",

            header:[
              {
                oggetti: ['prodotto'],
                modificabile: false,
                nome: 'Prodotto',
              },
              {
                oggetti: ['codiceAdhoc'],
                modificabile: false,
                nome: 'Cod. AdHoc',
              },
              {
                oggetti: ['posizione'],
                modificabile: false,
                nome: 'Posizione',
                inputType: 'text',
              },
            ],
            modificabile: true,
          },
        ],
      }
    ],
  },
  CalendarAddon: {
    managerScadenza : false,
  },
  RmaAddOn: {
    taskGenerico: false,
    vociDiCosto: true,
    hideSegnalazionidie: true,
    hideSegnalazionidfe: true,
    provaAcquisto: false,
    total: true,
    delete:true,
    CategoriaProdotto: true,
    TipoAcquisto: true,
    TipoAcquistoSource: "ClientiType",
    Rivenditore:true,
    NumeroDocumento: false,
    DataDocumento: true,
    Ricevuta: true,
    Targhetta: true,
    CircostanzaDifetto: true,
    DescrizioneDifetto: false,
    Difetto: true,
    Telefono: false,
    Garanzia: false,
    EstensioneGaranzia: false,
    Allegati: true,
    Modello: true,
    Matricola: true,
    Brand: true,
    sottoTaskrma: true,
    chiuso:true,
    dapertura_c: true,
    diniziop: false,
    dfinep: false,
    dinizioe: true,
    dfinee: true,
    Assistenza: {
      Enabled: true,
      Referente: false,
      Nominativo: true,
      Indirizzo: true,
      Citta: true,
      Cap: true,
      Provincia: true,
      Telefono: true,
      Email: true,
      Chiusura: true,
      Disponibilita: true,
      Tecnico: false,
    },

    CampiRmaAdd: [
      {
        sezione: 'LUOGO DI INTERVENTO',
        titleStyle: { 'font-weight': "700", 'padding': '0.8rem', 'background-color': 'whitesmoke', 'border-left': '5px solid #1898c6', 'max-width': '71%', 'color': '#1898c6' },
        campi: [
          {
            modificabile: true,
            nome: 'Attività/Ragione Sociale',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['nominativo'],
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Referente',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['referente'],
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Indirizzo',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['indirizzo'],
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Citta',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['citta'],
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
           
            class: 'col-lg-6',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Prov',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['provincia'],
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'CAP',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['cap'],
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Tel',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['telefono'],
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-5',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Email',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['email'],
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-5',
            prevDivClass: 'col-lg-12',
            visibleifProhibited: true,
            azioni: [
              { ExternalOnClick: 'mailTo', icon: 'fas fa-reply', hideif: {}, tooltip: 'Scrivi', color: 'green' },
            ],
          },
          {
            modificabile: true,
            nome: 'Giorno Chiusura',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['chiusura'],
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-4',
            visibleifProhibited: true
          }
        ]
      },
      {
        sezione: 'DATI DELLA MACCHINA',
        titleStyle: { 'font-weight': "700", 'padding': '0.8rem', 'background-color': 'whitesmoke', 'border-left': '5px solid #1898c6', 'max-width': '71%', 'color': '#1898c6' },
        campi: [
          {
            nome: 'Modello',
            source: 'https://customer.klimaitalia.it/api/prodotti/list',
            responseHasData: true,
            parameter: [{ nome: "page", value: 0 }, { nome: "size", value: 200 }],
            prevDivClass: "col-md-12 mb-2",
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            oggetti: ['prodotto'],
            internalOutput: 'modelloObject',
            OutputField: 'prodotto',
            modificabile: true,
            inputType: 'singleDropdown',
            placeholder: 'Modello',
            inputClass:'drop-fill',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'prodotto',
              textField: 'prodotto',
              searchPlaceholderText: "Cerca",
              allowSearchFilter: true,
              allowRemoteDataSearch: true,
            },
            azioni: [
              { ExternalOnClick: 'uploadTarghetta', icon: 'fas fa-upload', hideif: {}, tooltip: 'Allega la Targhetta', color: 'green', label:'Allega la Targhetta' },
            ],
            class: 'col-lg-9 mb-2' ,
            viewInList: true,
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Matricola',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            viewInList: true,
            oggetti: ['seriale'],
            inputType: 'text',
            class: 'col-lg-7',
            visibleifProhibited: true
          },
          {
            nome: 'NEUTRO/PUBBLICIZZATO*',
            source: '/rma/register/types',
            responseHasData: false,
            oggetti: ['brand'],
            internalOutput: 'brandObject',
            OutputField: 'brand',
            modificabile: true,
            inputType: 'singleDropdown',
            placeholder: 'Neutro/pubblicizzato',
            titledivClass: 'd-none',
            titleClass: 'input-group-text',
            inputClass: 'drop-fill',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'id',
              textField: 'label',
              searchPlaceholderText: "Cerca",
              allowSearchFilter: true,
              allowRemoteDataSearch: true,
            },
            class: 'col-lg-5 mb-2',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Brand',
            oggetti: ['brandDesc'],
            inputType: 'text',
            hideif: { fieldnotexist: 'brand', fieldNotEqual: [{ source: 'brand', value: 76 }] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Brand',
            oggetti: ['brandDesc'],
            inputType: 'text',
            hideif: { fieldnotexist: 'brand', fieldNotEqual: [{ source: 'brand', value: 551 }] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            nome: 'Fornitore',
            source: '/anagrafica/clienti/types?tipo=1',
            responseHasData: false,
            prevDivClass: "col-md-12",
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            oggetti: ['acquisto'],
            internalOutput: 'acquistoObject',
            OutputField: 'acquisto',
            modificabile: true,
            inputType: 'singleDropdown',
            inputClass: 'drop-fill',
            hideif: { hasRoles: ['C', 'AG'] },
            placeholder: 'Fornitore',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'id',
              textField: 'label',
              searchPlaceholderText: "Cerca",
              allowSearchFilter: true,
              allowRemoteDataSearch: true,
            },
            class: 'col-lg-12 mb-2',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Rag.Soc. del fornitore',
            oggetti: ['rivenditore'],
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            inputType: 'text',
            class: 'col-lg-12',
          },
          {
            nome: 'L\'articolo è',
            source: '/rma/rma/types',
            responseHasData: false,
            prevDivClass: "col-md-12",
            oggetti: ['difetto'],
            internalOutput: 'difettoObject',
            OutputField: 'difetto',
            modificabile: true,
            inputType: 'singleDropdown',
            placeholder: 'L\'articolo è',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            inputClass: 'drop-fill',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'id',
              textField: 'label',
              searchPlaceholderText: "Cerca",
              allowSearchFilter: true,
              allowRemoteDataSearch: true,
            },
            class: 'col-lg-12 mb-2',
            visibleifProhibited: true
          },

          {
            nome: 'Data di acquisto:', titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            prevDivClass: 'col-md-12',
            oggetti: ['dataDoc'],
            inputType: 'date',
            modificabile: true,
            class: 'col-lg-7',
            azioni: [
              { ExternalOnClick: 'uploadRicevuta', icon: 'fas fa-upload', hideif: {}, tooltip: 'Allega la Ricevuta d\'acquisto', color: 'green', label: 'Allega la Ricevuta d\'acquisto' },
            ]
          },
          {
            nome: 'Il Guasto è',
            source: '/rma/rma/guasti',
            responseHasData: false,
            prevDivClass: "col-md-12",
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            oggetti: ['guasto'],
            internalOutput: 'guastoObject',
            OutputField: 'guasto',
            modificabile: true,
            inputType: 'singleDropdown',
            placeholder: 'Guasto',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'id',
              textField: 'label',
              searchPlaceholderText: "Cerca",
              allowSearchFilter: true,
              allowRemoteDataSearch: true,
            },
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            nome: 'Guasto Segnalato:',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            prevDivClass: 'col-md-12',
            oggetti: ['note'],
            inputType: 'text',
            modificabile: true,
            class: 'col-lg-12',
          },
          // upload file
          // {
          //   modificabile: true,
          //   nome: 'Ricevuta d\'acquisto',
          //   oggetti: ['ricevuta'],
          //   multipart: true,
          //   prevDivClass: 'col-md-12',
          //   inputType: 'file',
          //   placeholder: '(pdf,doc,docx,xls,xlsx,jpg,png,txt,ods,odt) Size Limit: 5.00 MB',
          //   visibleifProhibited: true
          // },
          // {
          //   modificabile: true,
          //   nome: 'Targhetta',
          //   oggetti: ['targhetta'],
          //   multipart: true,
          //   inputType: 'file',
          //   placeholder: '(pdf,doc,docx,xls,xlsx,jpg,png,txt,ods,odt) Size Limit: 5.00 MB',
          //   visibleifProhibited: true
          // },
          

         
          {
            modificabile: true,
            nome: 'Nominativo',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['nominativo'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Referente',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['referente'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Indirizzo',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['indirizzo'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Citta',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['citta'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'CAP',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['cap'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Provincia',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['provincia'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Telefono',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['telefono'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Email',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['email'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true,
            azioni: [
              { ExternalOnClick: 'mailTo', icon: 'fas fa-reply', hideif: {}, tooltip: 'Scrivi', color: 'green' },
            ],
          }
        ]
      }
    
    ],
    SezioniRmaEdit: [
      {
        sezione: 'LUOGO DI INTERVENTO',
        titleStyle: { 'font-weight': "700", 'padding': '0.8rem', 'background-color': 'whitesmoke', 'border-left': '5px solid #1898c6', 'max-width': '71%', 'color': '#1898c6' },
        campi: [
          {
            modificabile: true,
            nome: 'Attività',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['nominativo'],
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Referente',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['referente'],
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Indirizzo',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['indirizzo'],
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Citta',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['citta'],
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',

            class: 'col-lg-6',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Prov',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['provincia'],
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'CAP',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['cap'],
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Tel. / Cel.',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['telefono'],
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-5',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Email',
            oggetti: ['ritiro'],
            type: 'object',
            field: ['email'],
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-5',
            prevDivClass: 'col-lg-12',
            visibleifProhibited: true,
            azioni: [
              { ExternalOnClick: 'mailTo', icon: 'fas fa-reply', hideif: {}, tooltip: 'Scrivi', color: 'green' },
            ],
          },
          {
            modificabile: true,
            nome: 'Giorno Chiusura',
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            oggetti: ['ritiro'],
            type: 'object',
            field: ['chiusura'],
            inputType: 'text',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-4',
            visibleifProhibited: true
          }
        ]
      },
      {
        titleStyle: { 'font-weight': "700", 'padding': '0.8rem', 'background-color': 'whitesmoke', 'border-left': '5px solid #1898c6', 'max-width': '71%', 'color': '#1898c6' },
        sezione: 'DATI DELLA MACCHINA',
        campi: [
          // {
          //   nome: 'Modello',
          //   source: 'https://customer.klimaitalia.it/api/prodotti/list',
          //   responseHasData: true,
          //   parameter: [{ nome: "page", value: 0 }, { nome: "size", value: 200 }],
          //   prevDivClass: "col-md-12 mb-2",
          //   titledivClass: 'input-group-prepend',
          //   titleClass: 'input-group-text',
          //   oggetti: ['prodotto'],
          //   internalOutput: 'modelloObject',
          //   OutputField: 'prodotto',
          //   modificabile: true,
          //   inputType: 'singleDropdown',
          //   placeholder: 'Modello',
          //   inputClass: 'drop-fill',
          //   settings: {
          //     singleSelection: true,
          //     closeDropDownOnSelection: true,
          //     singleOutput: true,
          //     idField: 'prodotto',
          //     textField: 'prodotto',
          //     searchPlaceholderText: "Cerca",
          //     allowSearchFilter: true,
          //     allowRemoteDataSearch: true,
          //   },
          //   azioni: [
          //     { ExternalOnClick: 'uploadTarghetta', icon: 'fas fa-upload', hideif: {}, tooltip: 'Allega la Targhetta', color: 'green' },
          //   ],
          //   class: 'col-lg-9 mb-2',
          //   viewInList: true,
          //   visibleifProhibited: true
          // },
          {
            nome: 'Modello',
            modificabile: true,
            disabledif: { hasRoles: ['C', 'AG', 'AC'], fieldexist: 'id'},
            oggetti: ['prodotto'],
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-9 mb-2',
            azioni: [
              { ExternalOnClick: 'uploadTarghetta', icon: 'fas fa-upload', hideif: {}, tooltip: 'Allega la Targhetta', color: 'green', label: 'Allega la Targhetta' },
            ],

          },
          {
            modificabile: true,
            nome: 'Matricola',
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            viewInList: true,
            oggetti: ['seriale'],
            inputType: 'text',
            class: 'col-lg-7',
            visibleifProhibited: true
          },
          {
            nome: 'NEUTRO/PUBBLICIZZATO*',
            source: '/rma/register/types',
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            responseHasData: false,
            oggetti: ['brand'],
            internalOutput: 'brandObject',
            OutputField: 'brand',
            modificabile: true,
            inputType: 'singleDropdown',
            placeholder: 'Neutro/pubblicizzato',
            titledivClass: 'd-none',
            titleClass: 'input-group-text',
            inputClass: 'drop-fill',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'id',
              textField: 'label',
              searchPlaceholderText: "Cerca",
              allowSearchFilter: true,
              allowRemoteDataSearch: true,
            },
            class: 'col-lg-5 mb-2',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Brand',
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            oggetti: ['brandDesc'],
            inputType: 'text',
            hideif: { fieldnotexist: 'brand', fieldNotEqual: [{ source: 'brand', value: 76 }] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            nome: 'Brand',
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            oggetti: ['brandDesc'],
            inputType: 'text',
            hideif: { fieldnotexist: 'brand', fieldNotEqual: [{ source: 'brand', value: 551 }] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            nome: 'Fornitore',
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            source: '/anagrafica/clienti/types?tipo=1',
            responseHasData: false,
            prevDivClass: "col-md-12",
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            oggetti: ['acquisto'],
            internalOutput: 'acquistoObject',
            OutputField: 'acquisto',
            modificabile: true,
            inputType: 'singleDropdown',
            inputClass: 'drop-fill',
            hideif: { hasRoles: ['C', 'AG'] },
            placeholder: 'Fornitore',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'id',
              textField: 'label',
              searchPlaceholderText: "Cerca",
              allowSearchFilter: true,
              allowRemoteDataSearch: true,
            },
            class: 'col-lg-12 mb-2',
            visibleifProhibited: true
          },
          {
            modificabile: true,
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            nome: 'Rag.Soc. del fornitore',
            oggetti: ['rivenditore'],
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            inputType: 'text',
            hideif: { hasRoles: ['C', 'AG'] },
            class: 'col-lg-12',
          },
          {
            nome: 'L\'articolo è',
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            source: '/rma/rma/types',
            responseHasData: false,
            prevDivClass: "col-md-12",
            oggetti: ['difetto'],
            internalOutput: 'difettoObject',
            OutputField: 'difetto',
            modificabile: true,
            inputType: 'singleDropdown',
            placeholder: 'L\'articolo è',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            inputClass: 'drop-fill',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'id',
              textField: 'label',
              searchPlaceholderText: "Cerca",
              allowSearchFilter: true,
              allowRemoteDataSearch: true,
            },
            class: 'col-lg-12 mb-2',
            visibleifProhibited: true
          },

          {
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            nome: 'Data di acquisto:', titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            prevDivClass: 'col-md-12',
            oggetti: ['dataDoc'],
            inputType: 'date',
            modificabile: true,
            class: 'col-lg-7',
            azioni: [
              { ExternalOnClick: 'uploadRicevuta', icon: 'fas fa-upload', hideif: {}, tooltip: 'Allega la Ricevuta d\'acquisto', color: 'green', label: 'Allega la Ricevuta d\'acquisto' },
            ]
          },
          {
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            nome: 'Il Guasto è',
            source: '/rma/rma/guasti',
            responseHasData: false,
            prevDivClass: "col-md-12",
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            oggetti: ['guasto'],
            internalOutput: 'guastoObject',
            OutputField: 'guasto',
            modificabile: true,
            inputType: 'singleDropdown',
            placeholder: 'Guasto',
            settings: {
              singleSelection: true,
              closeDropDownOnSelection: true,
              singleOutput: true,
              idField: 'id',
              textField: 'label',
              searchPlaceholderText: "Cerca",
              allowSearchFilter: true,
              allowRemoteDataSearch: true,
            },
            class: 'col-lg-12',
            visibleifProhibited: true
          },
          {
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            nome: 'Guasto Segnalato:',
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            prevDivClass: 'col-md-12',
            oggetti: ['note'],
            inputType: 'text',
            hideif: { fieldNotEqual: [{ source: 'guasto', value: 562 }] },
            modificabile: true,
            class: 'col-lg-12',
          },
          {
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            modificabile: true,
            nome: 'Nominativo',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['nominativo'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            modificabile: true,
            nome: 'Referente',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['referente'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            modificabile: true,
            nome: 'Indirizzo',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['indirizzo'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            modificabile: true,
            nome: 'Citta',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['citta'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            modificabile: true,
            nome: 'CAP',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['cap'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            modificabile: true,
            nome: 'Provincia',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['provincia'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            modificabile: true,
            nome: 'Telefono',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['telefono'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true
          },
          {
            disabledif: { hasRoles: ['C', 'AG', 'AC']},
            modificabile: true,
            nome: 'Email',
            oggetti: ['acquirente'],
            type: 'object',
            field: ['email'],
            inputType: 'text',
            hideif: { hasNotRoles: ['C', 'AG'] },
            titledivClass: 'input-group-prepend',
            titleClass: 'input-group-text',
            class: 'col-lg-3',
            visibleifProhibited: true,
            azioni: [
              { ExternalOnClick: 'mailTo', icon: 'fas fa-reply', hideif: {}, tooltip: 'Scrivi', color: 'green' },
            ],
          }
        ]
      }

    ],
    CampiProvaAcquisto: [
      {
        nome: 'Modello',
        source: 'https://customer.klimaitalia.it/api/prodotti/list',
        responseHasData: true,
        parameter: [{nome:"page", value: 0}, {nome:"size", value: 200}],
        prevDivClass: "col-md-12",
        oggetti: ['prodotto'],
        internalOutput: 'modelloObject',
        OutputField: 'prodotto',
        modificabile: true,
        inputType: 'singleDropdown',
        placeholder: 'Modello',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'prodotto',
          textField: 'prodotto',
          searchPlaceholderText: "Cerca",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        class: 'col-lg-3',
        viewInList: true,
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Matricola',
        viewInList: true,
        oggetti: ['seriale'],
        inputType: 'text',
        titleClass: 'control-label col-form-label',
        class: 'col-lg-4',
        visibleifProhibited: true
      },
     
      {
        modificabile: true,
        nome: 'Brand',
        oggetti: ['brandDesc'],
        inputType: 'text',
        hideif: { fieldnotexist: 'brand', fieldNotEqual: [{ source: 'brand', value: 76 }] },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-12',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Brand',
        oggetti: ['brandDesc'],
        inputType: 'text',
        hideif: { fieldnotexist: 'brand', fieldNotEqual: [{ source: 'brand', value: 551 }] },
        titleClass: 'control-label col-form-label',
        class: 'col-lg-12',
        visibleifProhibited: true
      },
      {
        nome: 'Fornitore',
        source: '/anagrafica/clienti/types?tipo=1',
        responseHasData: false,
        prevDivClass: "col-md-12",
        oggetti: ['acquisto'],
        internalOutput: 'acquistoObject',
        OutputField: 'acquisto',
        modificabile: true,
        inputType: 'singleDropdown',
        hideif: { hasRoles: ['C', 'AG'] },
        placeholder: 'Fornitore',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'label',
          searchPlaceholderText: "Cerca",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Rag.Soc. del fornitore',
        oggetti: ['rivenditore'],
        inputType: 'text',
        hideif:{hasRoles: ['C', 'AG']},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-4',
      },
      { nome:'Data di acquisto:', oggetti: ['dataDoc'],  inputType: 'date', modificabile: true, class: 'col-lg-3'},
      {
        modificabile: true,
        nome: 'Ricevuta d\'acquisto',
        oggetti: ['ricevuta'],
        multipart:true,
        prevDivClass: 'col-md-12',
        inputType: 'file',
        placeholder: '(pdf,doc,docx,xls,xlsx,jpg,png,txt,ods,odt) Size Limit: 5.00 MB',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Targhetta',
        oggetti: ['targhetta'],
        multipart:true,
        inputType: 'file',
        placeholder: '(pdf,doc,docx,xls,xlsx,jpg,png,txt,ods,odt) Size Limit: 5.00 MB',
        visibleifProhibited: true
      },
      {
        nome: 'L\'articolo è' ,
        source: '/rma/rma/types',
        responseHasData: false,
        prevDivClass: "col-md-12",
        oggetti: ['difetto'],
        internalOutput: 'difettoObject',
        OutputField: 'difetto',
        modificabile: true,
        inputType: 'singleDropdown',
        placeholder: 'L\'articolo è',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'label',
          searchPlaceholderText: "Cerca",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        nome: 'Neutro/pubblicizzato',
        source: '/rma/register/types',
        responseHasData: false,
        oggetti: ['brand'],
        internalOutput: 'brandObject',
        OutputField: 'brand',
        modificabile: true,
        inputType: 'singleDropdown',
        placeholder: 'Neutro/pubblicizzato',
        settings: {
          singleSelection: true,
          closeDropDownOnSelection: true,
          singleOutput: true,
          idField: 'id',
          textField: 'label',
          searchPlaceholderText: "Cerca",
          allowSearchFilter: true,
          allowRemoteDataSearch: true,
        },
        class: 'col-lg-6',
        visibleifProhibited: true
      },

     
      {
        modificabile: true,
        nome: 'Nominativo',
        oggetti: ['acquirente'],
        type: 'object',
        field:['nominativo'],
        inputType: 'text',
        hideif: {hasNotRoles: ['C', 'AG']},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Referente',
        oggetti: ['acquirente'],
        type: 'object',
        field:['referente'],
        inputType: 'text',
        hideif: {hasNotRoles: ['C', 'AG']},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Indirizzo',
        oggetti: ['acquirente'],
        type: 'object',
        field:['indirizzo'],
        inputType: 'text',
        hideif: {hasNotRoles: ['C', 'AG']},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Citta',
        oggetti: ['acquirente'],
        type: 'object',
        field:['citta'],
        inputType: 'text',
        hideif: {hasNotRoles: ['C', 'AG']},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'CAP',
        oggetti: ['acquirente'],
        type: 'object',
        field:['cap'],
        inputType: 'text',
        hideif: {hasNotRoles: ['C', 'AG']},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Provincia',
        oggetti: ['acquirente'],
        type: 'object',
        field:['provincia'],
        inputType: 'text',
        hideif: {hasNotRoles: ['C', 'AG']},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Telefono',
        oggetti: ['acquirente'],
        type: 'object',
        field:['telefono'],
        inputType: 'text',
        hideif: {hasNotRoles: ['C', 'AG']},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      },
      {
        modificabile: true,
        nome: 'Email',
        oggetti: ['acquirente'],
        type: 'object',
        field:['email'],
        inputType: 'text',
        hideif: {hasNotRoles: ['C', 'AG']},
        titleClass: 'control-label col-form-label',
        class: 'col-lg-3',
        visibleifProhibited: true
      }
    ],
  },
  //#endregion
  //#region Menu
  Menu: [
    {
      title: "Menu",
      class: "has-arrow",
      label: "Menu",
      extralink: true
    },
    {
      module: "Dashboard",
      path: "/dashboard/dashboard",
      title: 'menu.dashboard',
      icon: "mdi mdi-gauge",
      capacita: ["menu-dashboard"]
    },
    {
      module: "Personale",
      path: "/listautenti/personale",
      title:'menu.PersonaleLabel',
      icon: "far fa-user",
      capacita: ["menu-persone"]
    },
    {
      module: "Clienti",
      path: "/listautenti/aziende",
      title:'menu.clienti',
      icon: "mdi mdi-account-multiple",
      capacita: ["menu-clienti"]
    },
    {
      module: "Clienti",
      capacita: ["menu-clienti"],
      path: "/listautenti/aziende/ac",
      title: 'Acquirenti',
      icon: "mdi mdi-account-multiple",
    },
    {
      module: "Clienti",
      capacita: ["menu-clienti"],
      path: "/listautenti/aziende/c",
      title: 'Distributori',
      icon: "mdi mdi-account-multiple",
    },
    {
      module: "Clienti",
      capacita: ["menu-clienti"],
      path: "/listautenti/aziende/ca",
      title: 'Centri assistenza',
      icon: "mdi mdi-account-multiple",
    },
    {
      module:"Progetti",
      path: "progetti/progetti",
      title: 'menu.progetti',
      icon: "mdi mdi-calendar-multiple-check",
      capacita: ["menu-progetti"]
    },
    {
      module: "Task",
      path: "task/calendario",
      title: 'menu.calendario',
      icon: "mdi mdi-calendar",
    },
    {
      module: "Task",
      path: "task/list",
      title: 'menu.lista',
      icon: "mdi mdi-calendar"
    },
    {
      module: "Listini",
      path: "listino/listini",
      title: 'menu.listini',
      icon: "fas fa-bars",
      capacita: ["gestione-listino"],
    },
    {
      module: "Acquisti",
      path: "acquisti/acquisti",
      title: 'menu.acquisti',
      icon: "fas fa-cart-plus"
    },
    {
      module: "Acquisti",
      path: "acquisti/lavorazioni",
      title: 'menu.lavorazioni',
      icon: "fab fa-opencart"
    },
    {
      module: "Acquisti",
      path: "acquisti/groupedlavorazioni",
      title: 'menu.groupedlavorazioni',
      icon: "fab fa-opencart",
      capacita: ["menu-acquisti"],
    },
    {
      module: "Acquisti",
      path: "acquisti/spedizioni",
      title: 'menu.spedizioni',
      icon: "far fa-paper-plane"
    },
    {
      module: "Interventi",
      title: "Attività",
      icon: "mdi mdi-wrench",
      class: "has-arrow",
      capacita: ["menu-fasi"],
      submenu: [
        {
          path: "interventi/interventi",
          title: 'menu.interventi',
          icon: "mdi mdi-wrench",
        },
        {
          path: "interventi/gantt",
          title: "Gantt",
          icon: "mdi mdi-chart-areaspline",
        }
      ]
    },
    {
      module: "Documenti",
      path: "progetti/documenti",
      title: 'menu.DocumentiLabel',
      icon: "mdi mdi-file-document",
      capacita: ["menu-documenti"]
    },
    {
      module: "Prodotti",
      path: "progetti/prodotti/68/lista",
      title: 'menu.ProdottiLabel',
      icon: "mdi mdi-barcode-scan",
      capacita: ["menu-prodotti"]
    },
    {
      path:"progetti/codicisconto/69/lista",
      title: "Accessori",
      icon: "mdi mdi-cart",
      capacita: ["gestione-prodotti"]
    },
    {
      module: "Formazione",
      title: 'menu.FormazioneLabel',
      icon: "mdi mdi-school",//mdi-calendar-multiple-check //mdi-engine-outline
      class: "has-arrow",
      capacita: ["menu-servizi", "menu-formazione", "menu-calendar-servizi","menu-utenti-servizi"],
      submenu: [
        {
          path: "formazione/media",
          title: "menu.corsiMultimediali",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-formazione"]
        },
        {
          path: "formazione/corsi",
          title: "menu.corsi",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-calendar-servizi", "menu-servizi"]
        },
        {
          path: "/listautenti/personale/mod",
          title: "menu.docenti",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "/listautenti/personale/tut",
          title: "menu.tutor",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "/listautenti/personale/stu",
          title: "menu.studenti",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu-utenti-servizi"]
        },
        {
          path: "formazione/materie",
          title: "menu.materie",
          icon: "mdi mdi-currency-eur",
          capacita: ["menu_calendar_tirocini"]
        },
        {
          path: "formazione/sedi",
          title: "menu.sedi",
          icon: "mdi mdi-currency-eur",
        }
      ]
    },
    {
      module: "Prima_Nota",
      title: "menu.Prima_NotaLabel",
      icon: "mdi mdi-cart",//mdi-calendar-multiple-check //mdi-engine-outline
      class: "has-arrow",
      capacita: ["menu-spese","menu-fatture"],
      submenu: [
        {
          path: "primanota/fatture",
          title: "menu.fattVendita",
          capacita: ["menu-fatture"]
        },
        {
          path: "/listautenti/aziende/f",
          title: "menu.fornitori",
          capacita: ["menu-fatture"]
        },
        {
          path: "primanota/primanota",
          title: "menu.primanota",
          capacita: ["menu-spese"]
        },
        {
          path: "primanota/lista-spese",
          title: "menu.listaspese",
          capacita: ["menu-spese"]
        }
      ]
    },
    {
      module: "Parti",
      path: "progetti/parti",
      title: "menu.PartiLabel",
      icon: "mdi mdi-barcode",
      capacita: ["menu-parti"]
    },
    {
      module: "Parti",
      path: "progetti/parti/categorie-list",
      title: "Categorie Parti",
      icon: "mdi mdi-cart",
      capacita: ["menu-parti"]
    },
    {
      module: "Spedizioni",
      path: "spedizioni/lista",
      title: "menu.SpedizioniLabel",
      icon: "mdi mdi-airplane",
      capacita: ["menu-spedizioni"]
    },
    {
      module: "Stampe",
      path: "stampe/templates",
      title: "menu.StampeLabel",
      icon: "mdi mdi-printer",
      class: "has-arrow",
      capacita: ["menu-stampa"],
      submenu: [
        {
          path: "stampe/templates",
          title: "Templates",
          capacita: ["gestione-stampa"]
        },
        {
          path: "stampe/marker",
          title: "Marker",
          capacita: ["menu-stampa"]
        }
      ]
    },
    {
      module: "Settings",
      path: "settings/settings",
      title: "menu.SettingsLabel",
      icon: "mdi-settings",
      capacita: ["menu-settings"]
    },
    {
      module: "Admin",
      path: "listautenti/roles",
      title: "Ruoli",
      icon: "mdi mdi-lock-open-outline",
      capacita: ["menu-ruoli"]
    },
    {
      module: "Ticket",
      path: "ticket/list",
      title: "Tickets",
      icon: "mdi mdi-bookmark-outline",
      capacita: ["menu-ticket"]
    },
    {
      notruoliOperativi: ["CA","AC"],
      module: "Ecommerce",
      title: 'menu.EcommerceLabel',
      icon: 'mdi mdi-cart',
      class: 'has-arrow',
      submenu: [
        {
          path: '/ecom/cat',
          title: 'Products',
          icon: 'mdi mdi-cards-variant',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        },
        {
          path: '/ecom/cart',
          title: 'Cart',
          icon: 'mdi mdi-cart',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        },
        {
          path: '/ecom/orders',
          title: 'Orders',
          icon: 'mdi mdi-chart-pie',
          class: '',
          label: '',
          labelClass: '',
          extralink: false,
          submenu: []
        }
      ]
    },
    {
      ruoliOperativi:["AC","C","CA"],
      path: 'Cliente/profile',
      title: 'menu.profilo',
      icon: "far fa-user"
    }
  ],
  //#endregion
};


